import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Container } from '../ui';
import { 
    Component,
    Block,
    LogoBlock,
    LogoBlockSmall,
    DeliveryBlock,
    PhoneAndMenuButtonBlock,
    PhoneBlock,
    PhoneBlockTop,
    PhoneBlockBottom,
    FreeCall,
    FreeDelivery,
    MenuButtonBlock,
} from './components';

import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as LogoSmallOrangeIcon } from '../../assets/icons/logo-small-orange.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';

import { callCenterPhone } from '../../configs';
import MobileMenuComponent from '../mobileMenu';

const Header = ({ howToOrderRef, whyUsRef }) => {
    const [isActive, setIsActive] = useState(false);
    const [mobileMenuIsActive, setMobileMenuIsActive] = useState(false);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, []);


    const handleClickNumber = () => {
        console.log("click_to_number");
        window.gtag('event', 'call2', {'callto2': "446102323"});

    }

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }

    return (
        <Component className={isActive && 'active'}>
            <Container>
                <Block>
                    <LogoBlock>
                        <Link to="/">
                            <LogoIcon />                    
                        </Link>
                    </LogoBlock>

                    <LogoBlockSmall>
                        <Link to="/">
                            <LogoSmallOrangeIcon />
                        </Link>
                    </LogoBlockSmall>

                    <DeliveryBlock>
                        Доставка от 10 сомони по <span>Душанбе</span>
                    </DeliveryBlock>
                    
                    <PhoneAndMenuButtonBlock>
                        <PhoneBlock>
                            <PhoneBlockTop onClick={() => handleClickNumber() } href={`tel:${callCenterPhone}`}>
                                <PhoneIcon />
                                {callCenterPhone}            
                            </PhoneBlockTop>
                                
                            {/* <PhoneBlockBottom>
                                <FreeCall>или оставьте заявку</FreeCall>
                                <FreeDelivery>или оставьте заявку</FreeDelivery>
                            </PhoneBlockBottom> */}
                        </PhoneBlock>
                        

                        <MenuButtonBlock onClick={() => setMobileMenuIsActive(true)}>
                            <MenuIcon />
                        </MenuButtonBlock>
                    </PhoneAndMenuButtonBlock>
                </Block>
            </Container>

            <MobileMenuComponent 
                isActive={mobileMenuIsActive} 
                close={setMobileMenuIsActive} 
                howToOrderRef={howToOrderRef}
                whyUsRef={whyUsRef}
            />
        </Component>
    )
};

export default Header;
